import React, { ButtonHTMLAttributes } from 'react';

import styles from './styles.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    inverted?: boolean;
}

export default function Button({
    inverted,
    className,
    children,
    ...rest
}: Props) {
    return (
        <button
            className={`${styles.container} ${
                inverted ? styles.inverted : ''
            } ${className}`}
            {...rest}
        >
            <>{children}</>
        </button>
    );
}
